var render = function render(){var _vm=this,_c=_vm._self._c;return _c('user-callout-dismisser',{attrs:{"feature-name":"board_add_new_column_trigger_popover"},scopedSlots:_vm._u([{key:"default",fn:function({ dismiss, shouldShowCallout, isAnonUser }){return [(shouldShowCallout && !isAnonUser)?_c('gl-popover',{ref:"boardNewListButtonCallout",attrs:{"show":shouldShowCallout,"css-classes":[
        'gl-max-w-48',
        'gl-shadow-lg',
        'gl-p-2',
        'gl-bg-blue-50',
        'board-new-list-button-callout',
      ],"target":"boards-create-list","triggers":"manual","placement":"left","data-testid":"board-new-list-button-callout"}},[_c('h5',{staticClass:"gl-mt-0 gl-mb-3"},[_vm._v("\n        "+_vm._s(_vm.$options.i18n.title)+"\n        "),_c('span',{directives:[{name:"safe-html",rawName:"v-safe-html:[$options.safeHtmlConfig]",value:(_vm.emoji),expression:"emoji",arg:_vm.$options.safeHtmlConfig}],staticClass:"gl-ml-2"})]),_vm._v(" "),_c('p',{staticClass:"gl-my-2 gl-font-base"},[_vm._v("\n        "+_vm._s(_vm.$options.i18n.body)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"gl-display-flex gl-justify-content-end gl-mt-4 gl-mb-2"},[_c('gl-button',{staticClass:"gl-bg-transparent!",attrs:{"variant":"confirm","category":"secondary","data-testid":"board-new-list-button-callout-dismiss"},on:{"click":dismiss}},[_vm._v(_vm._s(_vm.$options.i18n.dismiss))])],1)]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }